import { useState, useCallback } from "react"
import { debounce } from "lodash"
import { useGLTF, useEnvironment, Text } from "@react-three/drei"
import { Select } from "@react-three/postprocessing"
import { Price } from "./Price"

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 kitchen.glb --transform
Files: kitchen.glb [134.46MB] > kitchen-transformed.glb [2.1MB] (98%)
*/
export function Scene(props) {
    // Load model
    const { nodes, materials } = useGLTF("/kitchen-model/kitchen-transformed.glb")
    // Load environment (using it only on the chairs, for reflections)
    const env = useEnvironment({ preset: "city" })
    // Hover state
    const [hovered, hover] = useState(null)
    // Debounce hover a bit to stop the ticker from being erratic
    const debouncedHover = useCallback(debounce(hover, 30), [])
    const over = (name) => (e) => (e.stopPropagation(), debouncedHover(name))
    // Get the priced item
    const price = { TOTAL: 5999, CARPET: 433, LAMPSOCKET: 77, CHAIRS: 255, TABLE: 1699, VASE: 44 }[hovered] ?? 5999
    return (
        <>
            <group {...props}>
                <mesh geometry={nodes.vase1.geometry} material={materials.gray} material-envMap={env} />
                <mesh geometry={nodes.bottle.geometry} material={materials.gray} material-envMap={env} />
                <mesh geometry={nodes.walls_1.geometry} material={materials.floor} />
                <mesh geometry={nodes.walls_2.geometry} material={materials.walls} />
                <mesh geometry={nodes.plant_1.geometry} material={materials.potted_plant_01_leaves} />
                <mesh geometry={nodes.plant_2.geometry} material={materials.potted_plant_01_pot} />
                <mesh geometry={nodes.cuttingboard.geometry} material={materials.walls} />
                <mesh geometry={nodes.bowl.geometry} material={materials.walls} />
                <Select enabled={hovered === "CARPET"} onPointerOver={over("CARPET")} onPointerOut={() => debouncedHover(null)}>
                    <mesh geometry={nodes.carpet.geometry} material={materials.carpet} />
                </Select>
                <Select enabled={hovered === "TABLE"} onPointerOver={over("TABLE")} onPointerOut={() => debouncedHover(null)}>
                    <mesh geometry={nodes.table.geometry} material={materials.walls} material-envMap={env} material-envMapIntensity={0.5} />
                </Select>
                <Select enabled={hovered === "CHAIRS"} onPointerOver={over("CHAIRS")} onPointerOut={() => debouncedHover(null)}>
                    <mesh geometry={nodes.chairs_1.geometry} material={materials.walls} />
                    <mesh geometry={nodes.chairs_2.geometry} material={materials.plastic} material-color="#1a1a1a" material-envMap={env} />
                </Select>
                <Select enabled={hovered === "VASE"} onPointerOver={over("VASE")} onPointerOut={() => debouncedHover(null)}>
                    <mesh geometry={nodes.vase.geometry} material={materials.red} material-envMap={env} />
                </Select>
                <Select enabled={hovered === "LAMP SOCKET"} onPointerOver={over("LAMPSOCKET")} onPointerOut={() => debouncedHover(null)}>
                    <mesh geometry={nodes.lamp_socket.geometry} material={materials.gray} material-envMap={env} />
                    <mesh geometry={nodes.lamp.geometry} material={materials.gray} />
                    <mesh geometry={nodes.lamp_cord.geometry} material={materials.gray} material-envMap={env} />
                </Select>
                <mesh geometry={nodes.kitchen.geometry} material={materials.walls} />
                <mesh geometry={nodes.sink.geometry} material={materials.chrome} material-envMap={env} />
            </group>
            <Text position={[1, 1.25, 2]} color="black" fontSize={0.25} font="Inter-Regular.woff" letterSpacing={-0.05}>
                {hovered ? hovered : "ANYDAY FURNITURE SHOP"}
            </Text>
            {/** Forward the price to the ticker component */}
            <Price value={price} position={[-2, 0.3, -3.25]} />
        </>
    )
}
