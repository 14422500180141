import React, { useReducer, useMemo, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Physics } from '@react-three/rapier';
import Pointer from './Pointer';
import Connector from './Connector';
import { MeshTransmissionMaterial } from '@react-three/drei';
import { EffectComposer } from '@react-three/postprocessing';
import { N8AO } from '@react-three/postprocessing';
import { Lightformer } from '@react-three/drei';
import { Environment } from '@react-three/drei';
import DiamondModel from './DiamondModel';
import RotatingGroup from './RotatingGroup';

const accents = ['#14d4df', '#ec18fd']

const shuffle = (accent = 0) => [
    { color: 'red', roughness: 0.75, transparent: true, opacity: 100 },
    { color: '#f4f5e1', roughness: 0.75, transparent: true, opacity: 100 },
    { color: 'blue', roughness: 0.1, transparent: true, opacity: 100 },
    { color: 'red', roughness: 0.75, transparent: true, opacity: 100 },
    { color: '#f4f5e1', roughness: 0.75, transparent: true, opacity: 100 },
    { color: 'blue', roughness: 0.1, transparent: true, opacity: 100 },
    { color: accents[accent], roughness: 0.75, transparent: true, opacity: 100 },
    { color: accents[accent], roughness: 0.75, transparent: true, opacity: 100 },
    { color: accents[accent], roughness: 0.1, transparent: true, opacity: 100 }
]

export default function DiamondScene(props) {
    const [accent, click] = useReducer((state) => ++state % accents.length, 0)
    const connectors = useMemo(() => shuffle(accent), [accent])

    return (
        <Canvas onClick={click} shadows dpr={[1, 1.5]} gl={{ antialias: true }} camera={{ position: [10, 0, 5], fov: 400, near: 1, far: 20 }} {...props}>

            {/* Bg color and lighting */}
            <color attach="background" args={['#fff']} />
            <ambientLight intensity={0.2} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={5} castShadow />

            {/* Physics properties (Gravity) */}
            <Physics /*debug*/ gravity={[0, 0, 0]}>
                <Pointer />
                {/* Rotating group of diamonds */}
                <RotatingGroup connectors={connectors}>
                    <Connector position={[10, 10, 5]}>
                        <DiamondModel>
                            <MeshTransmissionMaterial clearcoat={1} thickness={0.1} anisotropicBlur={0.1} chromaticAberration={0.1} samples={8} resolution={512} />
                        </DiamondModel>
                    </Connector>
                </RotatingGroup>
            </Physics>

            {/* After effects for realistic view */}
            <EffectComposer disableNormalPass multisampling={8}>
                <N8AO distanceFalloff={1} aoRadius={1} intensity={4} />
            </EffectComposer>
            <Environment resolution={100} files="/diamond-model/textures/golden_bay_4k.hdr" >
                {/* <Environment resolution={1080} files="/diamond-model/textures/studio.hdr"> */}
                <group rotation={[-Math.PI / 3, 0, 1]}>
                    <Lightformer form="circle" intensity={4} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={2} />
                    <Lightformer form="circle" intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={2} />
                    <Lightformer form="circle" intensity={2} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={2} />
                    <Lightformer form="circle" intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={8} />
                </group>
            </Environment>
        </Canvas>
    )
}
