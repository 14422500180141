import React, { useState } from 'react';
import { motion } from 'framer-motion';
import AnyDayLogo from '../../assets/anydayllc-logo.png';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import OscillatingWaveButton from './Sine';
import { Donuts } from '../Donuts/Donuts';
import { ShopWindow } from '../ShopWindow/ShopWindow';
import DiamondScene from '../Diamond3DModel/DiamondScene';
import EmailButton from '../AnimatedButton/EmailButton';
import MenuButton from '../MenuButton/MenuButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Contact from '../Contact/Contact';
import About from '../About/About';
import Home from '../Home/Home';
import NotFound from '../NotFound/NotFound';
import { Portfolio } from '../Portfolio/Portfolio';
import { AnimatedRoutes } from '../../App';

const Header = () => {
  const [flipped, setFlipped] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<string>("");
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();



  const handleClick = () => {
    setFlipped(!flipped); // Toggle flip state
  };

  


  return (
    <div className="relative w-full h-[100vh] bg-white">
      {/* Card container that flips */}
      <motion.div
        className="relative w-full h-full"
        style={{
          perspective: '1000px', // Gives a 3D effect to the flip
        }}
      >
        <motion.div
          className="absolute w-full h-full"
          initial={false}
          animate={{
            rotateX: flipped ? 180 : 0,
          }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
          style={{
            transformStyle: 'preserve-3d', // Ensures 3D space for the flip
            position: 'relative',
          }}
        >
          {/* Front Side - Diamonds & Header */}
          <motion.div
            className={`absolute w-[95vw] h-full`}
            initial={false}
            animate={{
              opacity: flipped ? 0 : 1,
            }}
            transition={{ opacity: { duration: 0.8, delay: flipped ? 0.8 : 0 } }} // Delay opacity change on flip
            style={{
              backfaceVisibility: 'hidden', // Hide backface when flipped
            }}
          >
            {/* Header */}
          <div className="flex flex-col lg:flex-row justify-center items-end gap-6 lg:gap-[100px] px-4 lg:px-[2px] py-5 lg:py-[0px] relative w-[100vw] lg:w-[100vw] bg-gray-100">
            {/* Logo Section */}
            <div className="logo-container lg:mb-0 lg:mr-[35px] cursor-pointer" onClick={handleClick}>
              <img src={AnyDayLogo} alt="logo" className="h-[60px] lg:h-[80px] lg:w-[15vw]" />
            </div>

            {/* Text Section */}
            <div className="text-center lg:text-left text-sm lg:text-md font-light font-sans max-w-[90%] lg:max-w-[500px] max-h-[20px] text-gray-950 ">
              We help brands design immersive web experiences for their customers
            </div>

            {/* Button Section */}
            <div className="flex justify-around items-center relative mt-4 lg:mt-0">
            <div className='flex justify-between lg:w-[35vw]'> 
              <div className='ml-[20px]'><EmailButton /></div>
              <div></div>
              <div className='flex justify-start items-start lg:w-[10vw]'>
                <div className=''><OscillatingWaveButton /></div>
              <div className='mr-[20px]'><MenuButton /></div>
              </div>
            </div>
  </div>
  </div>

          {/* Main Section */}
          <AnimatedRoutes />
          </motion.div>

          {/* Back Side - ShopWindow */}
          <motion.div
            className={`absolute w-full h-full`}
            initial={false}
            animate={{
              opacity: flipped ? 1 : 0,
            }}
            transition={{ opacity: { duration: 0.8, delay: flipped ? 0 : 0.8 } }} // Delay opacity change on flip
            style={{
              backfaceVisibility: 'hidden', // Hide backface when flipped
              transform: 'rotateY(180deg)', // Flip this side so it is visible when rotated
            }}
          >
            <div className="text-gray-900 cursor-pointer shadow-lg text-center h-[100vh] w-[100vw] transform rotate-180">
              <ShopWindow />
            </div>

            {/* Close Button */}
            <motion.div
              className="absolute bottom-20 left-20 text-[48px] cursor-pointer z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2, ease: 'easeOut' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon icon={faXmark} className="text-gray-800" />
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Header;