import React, { useRef, useMemo } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { RigidBody } from '@react-three/rapier';
import { CuboidCollider } from '@react-three/rapier';
import DiamondModel from './DiamondModel';
import { MeshTransmissionMaterial } from '@react-three/drei';


export default function Connector({ position, children, vec = new THREE.Vector3(), scale, r = THREE.MathUtils.randFloatSpread, accent, ...props }) {
    const api = useRef()
    const pos = useMemo(() => position || [r(10), r(10), r(10)], [])
    useFrame((state, delta) => {
        delta = Math.min(0.1, delta)
        api.current?.applyImpulse(vec.copy(api.current.translation()).negate().multiplyScalar(0.2))
    })
    return (
        <RigidBody linearDamping={4} angularDamping={1} friction={0.1} position={pos} ref={api} colliders={false}>
            <CuboidCollider args={[0.38, 1.27, 0.38]} />
            <CuboidCollider args={[1.27, 0.38, 0.38]} />
            <CuboidCollider args={[0.38, 0.38, 1.27]} />
            {/* {children ? children : <DiamondModel {...props} />} */}
            <DiamondModel {...props}>
                <MeshTransmissionMaterial
                    // clearcoat={1}               // Adds a shiny coat
                    thickness={2}               // The thickness of the diamond (adjust as needed)
                    // anisotropicBlur={0.05}      // Subtle light distortion
                    chromaticAberration={0.1}   // Light dispersion effect
                    ior={2.417}                 // Index of Refraction for diamonds (around 2.4)
                    transmission={1}            // Makes the object transparent
                    // metalness={0}               // No metal, but reflective
                    roughness={0}               // Low roughness for clear reflections
                // envMapIntensity={1}         // Adjust this to enhance reflections from environment maps
                // attenuationColor="#ffffff"  // A white attenuation color to give realistic light interactions
                // attenuationDistance={0.5}   // Controls the fading of light within the diamond
                // distortion={0.1}            // Small distortion for a realistic gem-like look
                // distortionScale={0.3}       // Scale for the distortion effect
                // samples={32}
                // scale={10}             // Higher sample count for better quality
                />

            </DiamondModel>
            {accent && <pointLight intensity={4} distance={2.5} color={props.color} />}
        </RigidBody >
    )
}