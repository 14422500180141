import { useGLTF } from '@react-three/drei';
import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { easing } from 'maath'
import { MeshStandardMaterial } from 'three';

export default function DiamondModel({ children, color = 'red', roughness = 0, ...props }) {
    const ref = useRef();
    const { nodes, materials } = useGLTF('/diamond-model/round_diamond_57_edges.glb');

    useFrame((state, delta) => {
        easing.dampC(ref.current.material.color, color, 0.8, delta)
    })

    return (
        <mesh
            ref={ref}
            castShadow
            receiveShadow
            scale={2}
            geometry={nodes.Object_2.geometry}
        >
            {children}
        </mesh>
    );
}
