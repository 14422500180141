import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const textMotion = {
    rest: {
        x: 0,
        color: "#fff", // White text at rest
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeIn",
        },
    },
    hover: {
        x: 15, // Move 30px to the right
        color: "#fff", // Change to red on hover
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeOut",
        },
    },
};

const buttonColorChange = {
    rest: {
        backgroundColor: "#14d4df", // Initial background color
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeIn",
        },
    },
    hover: {
        backgroundColor: "#ec18fd", // Hover background color
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeOut",
        },
    },
};

const showArrow = {
    rest: {
        x: 0,
        opacity: 0,
        color: "#fff",
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeIn",
        },
    },
    hover: {
        x: 20, // Move 30px to the right
        // opacity: 1,
        color: "#fff", // Change to red on hover
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeOut",
        },
    },
};

const dotMotion = {
    rest: {
        x: 0,
        color: "#fff", // White text at rest
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeIn",
        },
    },
    hover: {
        x: 20, // Move 30px to the right
        color: "#fff", // Change to red on hover
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeOut",
        },
    },
};

const EmailButton = () => {
    // Track hover state
    const [isHovered, setIsHovered] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();

    const handleAnimating = () => {
        setIsAnimating(true);
        setTimeout(function () {
            setIsAnimating(false);
        }, 2000)
    }

    return (
        <motion.button
            initial="rest"
            animate={isAnimating ? "hover" : "rest"} // Sync hover state
            onHoverStart={() => setIsHovered(true)} // Handle hover start
            onHoverEnd={() => setIsHovered(false)} // Handle hover end
            className="pr-8 py-3 rounded-full cursor-pointer relative flex justify-start items-center"
            variants={buttonColorChange}
            onClick={() => { handleAnimating(); navigate('/contact'); }}

        >
            <motion.span
                className="font-extrabold"
                variants={showArrow}
                animate={isAnimating ? "hover" : "rest"} // Sync text with button hover
            >→
            </motion.span>
            <motion.p
                className="font-extrabold whitespace-nowrap text-sm"
                variants={textMotion}
                animate={isAnimating ? "hover" : "rest"}
            >LET'S TALK</motion.p>

            <motion.span
                className="absolute right-4 top-1/2 transform -translate-y-1/2 border-t border-white border-[2px] w-auto rounded-lg"
                variants={dotMotion}
                animate={isAnimating ? "hover" : "rest"}></motion.span>
        </motion.button>
    );
};

export default EmailButton;
