import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

export default function OscillatingWaveButton() {
  const pathRef = useRef<SVGPathElement | null>(null); // Reference to the SVG path element
  const [isAnimating, setIsAnimating] = useState(false); // Track if the button is clicked
  const [amplitude, setAmplitude] = useState(0); // Amplitude of the wave
  const controls = useAnimation(); // Framer motion controls
  const audioRef = useRef<HTMLAudioElement>(null); // Reference to the audio element
  const [isHovered, setIsHovered] = useState(false);

  let t = 0; // Time variable for wave animation

  // Generate x-coordinates for the wave
  const xs = Array.from({ length: 35 }, (_, i) => i); // Adjust for smaller button

  // Function to create the path for the wave based on the amplitude
  function generateWavePath() {
    const points = xs.map((x) => {
      const y = 25 + amplitude * Math.sin((x + t) / 10); // Adjust y-position for smooth transition
      return `${x},${y}`;
    });
    return 'M' + points.join(' L');
  }

  // Use Framer Motion's animation frame to animate the wave smoothly
  useEffect(() => {
    const animateWave = () => {
      if (pathRef.current) {
        const path = generateWavePath(); // Generate the wave path
        pathRef.current.setAttribute('d', path); // Update the path's 'd' attribute
        t += 0.5; // Increment the time variable to animate
      }
      requestAnimationFrame(animateWave); // Loop the animation
    };
    animateWave();
  }, [amplitude]);

  // Smoothly adjust the amplitude when the button is clicked
  useEffect(() => {
    let animationFrame: number;
    const adjustAmplitude = () => {
      setAmplitude((prevAmplitude) => {
        const targetAmplitude = isAnimating ? 10 : 0; // Transition between wave and straight line
        const difference = targetAmplitude - prevAmplitude;

        // Small incremental changes to smooth the transition
        const step = difference * 0.1;

        // If the amplitude is close to the target, snap to it
        if (Math.abs(difference) < 0.1) return targetAmplitude;

        return prevAmplitude + step;
      });

      animationFrame = requestAnimationFrame(adjustAmplitude); // Continue adjusting amplitude
    };

    adjustAmplitude(); // Start adjusting the amplitude

    return () => cancelAnimationFrame(animationFrame); // Clean up the animation
  }, [isAnimating]);

  // Function to toggle audio playback when the button is clicked
  const handleClick = () => {
    setIsAnimating(!isAnimating); // Toggle the wave animation

    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play(); // Play the audio if paused
      } else {
        audioRef.current.pause(); // Pause the audio if playing
        audioRef.current.currentTime = 0; // Reset audio to the start
      }
    }
  };

  // Add useEffect to loop the audio indefinitely
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.loop = true; // Ensure the audio loops indefinitely
    }
  }, []);

  const buttonColorChange = {
    rest: {
        backgroundColor: "#ec18fd",
        filter: "brightness(1)",
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeIn",
        },
    },
    hover: {
        backgroundColor: "#ec18ff",
        filter: "brightness(1.5)",
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeOut",
        },
    },
};

  return (
    <div>
      <motion.button
        onClick={handleClick} // Trigger the wave animation and audio playback on click
        className="relative flex items-center justify-center w-[45px] h-[45px] bg-[#ec18fd] text-white rounded-full"
        initial="rest"
        animate={isHovered ? "hover" : "rest"} 
        onHoverStart={() => setIsHovered(true)} // Handle hover start
        onHoverEnd={() => setIsHovered(false)} // Handle hover end
        variants={buttonColorChange}
      >
        <motion.svg width="35" height="35" viewBox="0 0 35 50"> {/* Adjusted viewBox and dimensions */}
          {/* Path that will be animated */}
          <motion.path
            ref={pathRef}
            stroke="#fff"
            strokeWidth="3"
            strokeLinecap="round"
            fill="none"
            animate={controls} // Control the amplitude using Framer Motion
          />
        </motion.svg>
      </motion.button>

      {/* Audio element to play the sound when button is pressed */}
      <audio ref={audioRef}>
        <source src="/aesthetic-tollankim.mp3" type="audio/mp3" /> {/* Updated path */}
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}
