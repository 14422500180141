import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const textColor = {
    rest: {
        color: "#fff",
        transition: {
            duration: 0.4,
        },
    },
    hover: {
        color: "#fff",
        transition: {
            duration: 0.4,
        },
    },
};

const buttonColorChange = {
    rest: {
        backgroundColor: "#ec18fd",
        filter: "brightness(1)",
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeIn",
        },
    },
    hover: {
        backgroundColor: "#ec18ff",
        filter: "brightness(1.5)",
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeOut",
        },
    },
};

const rotateMotion = {
    rest: {
        color: "#fff",
        rotate: 0,
        transformOrigin: "center",
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeIn",
        },
    },
    hover: {
        color: "#fff",
        rotate: 90,
        transformOrigin: "center",
        transition: {
            duration: 0.4,
            type: "spring",
            ease: "easeOut",
        },
    },
};

const backgroundColorChange = {
    rest: {
        backgroundColor: "rgba(0, 0, 0, 0)",
        padding: "5px 6px",
        color: "rgb(0, 0, 0)",
    },
    hover: {
        padding: "6px 7px",
        color: "#fff",
        backgroundColor: "#14d4df",
        transition: { duration: 0.4 },
    },
};

const leavingTextEffect = {
    rest: {
        y: 0,
        opacity: 1
    },
    hover: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.4 }
    }
}

const comingTextEffect = {
    rest: {
        y: 30,
        opacity: 0
    },
    hover: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.4 }
    }
}

const arrowAnimation = {
    rest: {
        scaleX: 0,
        color: "rgba(0, 0, 0, 0)"
    },
    hover: {
        scaleX: 1,
        transition: { duration: 0.5 },
        color: "#fff"
    },
};

const MenuButton = () => {
    const [isHovered, setIsHovered] = useState(false);
    // const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [hoveredItem, setHoveredItem] = useState("");
    const [isAnimating, setIsAnimating] = useState(false);

    // Ref to track the menu container
    const menuRef = useRef(null);
    const navigate = useNavigate();

    // const handleOpenMenu = () => {
    //     setIsMenuOpen(!isMenuOpen);
    // }

    // Function to check if a click is outside of the menu
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false); // Close the menu if clicked outside
        }
    }

    const handleNavigate = (path) => {
        navigate(path);
        setTimeout(() => setIsMenuOpen(false), 100);
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    useEffect(() => {
        // Add event listener to detect clicks outside
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={menuRef} className="relative">
            <motion.button
                initial="rest"
                animate={isHovered ? "hover" : "rest"} // Sync hover state
                onHoverStart={() => setIsHovered(true)} // Handle hover start
                onHoverEnd={() => setIsHovered(false)} // Handle hover end
                onTouchStart={() => setIsHovered(true)}
                onTouchEnd={() => setIsHovered(false)}
                className="py-3 rounded-full cursor-pointer relative flex justify-start items-center"
                variants={buttonColorChange}
                onClick={toggleMenu}
            >
                <motion.p
                    className="font-extrabold ml-4 text-sm"
                    variants={textColor}
                    animate={isMenuOpen ? "hover" : "rest"}
                >
                    MENU
                </motion.p>

                <motion.div
                    className="flex justify-center items-center mr-4 -space-x-2"
                    variants={rotateMotion}
                    animate={isMenuOpen ? "hover" : "rest"}
                >
                    <motion.span className="border-[2px] border-r border-white rounded-full p-0 m-0"></motion.span>
                    <motion.span className="border-[2px] border-l border-white rounded-full p-0 m-0"></motion.span>
                </motion.div>
            </motion.button>

            {/* Menu */}
            {isMenuOpen && (
                <motion.div className="absolute lg:right-4 right-0 top-[60px] px-4 lg:px-[35px] py-4 lg:py-[20px] bg-gray-100 border-[2px] w-[50vw] lg:w-[20vw] z-10 rounded-md shadow-sm">
                    <motion.ul className="text-xl lg:text-2xl">

                        {/* Home */}
                        <motion.li
                            className="rounded-full relative cursor-pointer touch-auto:bg-red-500"
                            onHoverStart={() => setHoveredItem("home")}
                            onHoverEnd={() => setHoveredItem("")}
                            animate={hoveredItem === "home" ? "hover" : "rest"}
                            variants={backgroundColorChange}
                        >
                            <motion.button
                                onClick={() => handleNavigate("/")}>
                                <motion.p
                                    className="absolute"
                                    onHoverStart={() => setHoveredItem("home")}
                                    onHoverEnd={() => setHoveredItem("")}
                                    onTouchStart={() => setIsHovered("home")}
                                    onTouchEnd={() => setIsHovered("")}
                                    animate={hoveredItem === "home" ? "hover" : "rest"}
                                    variants={leavingTextEffect}
                                >
                                    Home
                                </motion.p>
                                <motion.p
                                    onHoverStart={() => setHoveredItem("home")}
                                    onHoverEnd={() => setHoveredItem("")}
                                    onTouchStart={() => setIsHovered("home")}
                                    onTouchEnd={() => setIsHovered("")}
                                    animate={hoveredItem === "home" ? "hover" : "rest"}
                                    variants={comingTextEffect}
                                >
                                    Home
                                </motion.p>
                                <motion.span
                                    className="absolute right-5 bottom-0 p-1"
                                    onHoverStart={() => setHoveredItem("home")}
                                    onHoverEnd={() => setHoveredItem("")}
                                    onTouchStart={() => setIsHovered("home")}
                                    onTouchEnd={() => setIsHovered("")}
                                    animate={hoveredItem === "home" ? "hover" : "rest"}
                                    variants={arrowAnimation}
                                >
                                    →
                                </motion.span>
                            </motion.button>
                        </motion.li>

                        {/* About */}
                        <motion.li
                            className="rounded-full relative cursor-pointer touch-auto:bg-red-500"
                            onHoverStart={() => setHoveredItem("about")}
                            onHoverEnd={() => setHoveredItem("")}
                            onTouchStart={() => setIsHovered("about")}
                            onTouchEnd={() => setIsHovered("")}
                            animate={hoveredItem === "about" ? "hover" : "rest"}
                            variants={backgroundColorChange}
                            onClick={() => handleNavigate('/about')}
                        >
                            <motion.p
                                className="absolute"
                                onHoverStart={() => setHoveredItem("about")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("about")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "about" ? "hover" : "rest"}
                                variants={leavingTextEffect}
                            >
                                About
                            </motion.p>
                            <motion.p
                                onHoverStart={() => setHoveredItem("about")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("about")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "about" ? "hover" : "rest"}
                                variants={comingTextEffect}
                            >
                                About
                            </motion.p>
                            <motion.span
                                className="absolute right-5 bottom-0 p-1"
                                onHoverStart={() => setHoveredItem("about")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("about")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "about" ? "hover" : "rest"}
                                variants={arrowAnimation}
                            >
                                →
                            </motion.span>
                        </motion.li>

                        {/* Portfolio */}
                        <motion.li
                            className="rounded-full relative cursor-pointer mt-2"
                            onHoverStart={() => setHoveredItem("portfolio")}
                            onHoverEnd={() => setHoveredItem("")}
                            onTouchStart={() => setIsHovered("portfolio")}
                            onTouchEnd={() => setIsHovered("")}
                            animate={hoveredItem === "portfolio" ? "hover" : "rest"}
                            variants={backgroundColorChange}
                            onClick={() => handleNavigate('/portfolio')}
                        >
                            <motion.p
                                className="absolute"
                                onHoverStart={() => setHoveredItem("portfolio")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("portfolio")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "portfolio" ? "hover" : "rest"}
                                variants={leavingTextEffect}
                            >
                                Portfolio
                            </motion.p>
                            <motion.p
                                onHoverStart={() => setHoveredItem("portfolio")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("portfolio")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "portfolio" ? "hover" : "rest"}
                                variants={comingTextEffect}
                            >
                                Portfolio
                            </motion.p>
                            <motion.span
                                className="absolute right-5 bottom-0 p-1"
                                onHoverStart={() => setHoveredItem("portfolio")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("portfolio")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "portfolio" ? "hover" : "rest"}
                                variants={arrowAnimation}
                            >
                                →
                            </motion.span>
                        </motion.li>

                        {/* Contact */}
                        <motion.li
                            className="rounded-full relative cursor-pointer mt-2"
                            onHoverStart={() => setHoveredItem("contact")}
                            onHoverEnd={() => setHoveredItem("")}
                            onTouchStart={() => setIsHovered("contact")}
                            onTouchEnd={() => setIsHovered("")}
                            animate={hoveredItem === "contact" ? "hover" : "rest"}
                            variants={backgroundColorChange}
                            onClick={() => handleNavigate('/contact')}
                        >
                            <motion.p
                                className="absolute"
                                onHoverStart={() => setHoveredItem("contact")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("contact")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "contact" ? "hover" : "rest"}
                                variants={leavingTextEffect}
                            >
                                Contact
                            </motion.p>
                            <motion.p
                                onHoverStart={() => setHoveredItem("contact")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("contact")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "contact" ? "hover" : "rest"}
                                variants={comingTextEffect}
                            >
                                Contact
                            </motion.p>
                            <motion.span
                                className="absolute right-5 bottom-0 p-1"
                                onHoverStart={() => setHoveredItem("contact")}
                                onHoverEnd={() => setHoveredItem("")}
                                onTouchStart={() => setIsHovered("contact")}
                                onTouchEnd={() => setIsHovered("")}
                                animate={hoveredItem === "contact" ? "hover" : "rest"}
                                variants={arrowAnimation}
                            >
                                →
                            </motion.span>
                        </motion.li>
                    </motion.ul>
                </motion.div>
            )}
        </div>
    );
};

export default MenuButton;
