import React, { useState, useEffect, useRef } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Sky, Bvh, useMask } from "@react-three/drei"
import { EffectComposer, Selection, Outline, N8AO, TiltShift2, ToneMapping } from "@react-three/postprocessing"
import { easing } from "maath"
import { motion } from "framer-motion";
import AFormation from './AFormation'; // Import AFormation component
import { OrbitControls } from '@react-three/drei';
import { Text } from '@react-three/drei';
import { Counter } from './Counter';



export default function LoadingComponent({ onComplete, path }) {
    const [progress, setProgress] = useState(0);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [animationComplete, setAnimationComplete] = useState(false);
    const [fov, setFov] = useState(50);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setFov(100);
            } else {
                setFov(50);
            }
        }
        // Set initial FOV based on current window size
        handleResize();

        // Add resize listener
        window.addEventListener('resize', handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Simulate loading progress (replace with real loading logic)
    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval); // Stop the progress update
                    return prev; // Prevent progress from going above 100
                }
                return prev + 1; // Increment progress
            });
        }, 30); // Adjust speed of progress as necessary

        // Once progress reaches 100, we trigger loading completion and the onComplete callback
        if (progress >= 100) {
            setLoadingComplete(true); // Set loading complete to true when progress reaches 100
            setTimeout(() => {
                onComplete(); // Call onComplete after 2-second delay
            }, 11000); // Delay before revealing the page
        }

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [progress, onComplete]);

    function Effects() {
        const { size } = useThree()
        useFrame((state, delta) => {
            easing.damp3(state.camera.position, [state.pointer.x, 1 + state.pointer.y / 2, 8 + Math.atan(state.pointer.x * 2)], 0.3, delta)
            state.camera.lookAt(state.camera.position.x * 0.9, 0, -4)
        })
        return (
            <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
                <N8AO halfRes aoSamples={5} aoRadius={0.4} distanceFalloff={0.75} intensity={1} />
                <Outline visibleEdgeColor="white" hiddenEdgeColor="white" blur width={size.width * 1.25} edgeStrength={10} />
                <TiltShift2 samples={5} blur={0.1} />
                <ToneMapping />
            </EffectComposer>
        )
    }
    return (

        <div className="relative lg:h-[100vh] lg:w-[100vw] w-[100vw]">
            {loadingComplete ? (
                <motion.div
                    className="relative h-[100vh]"
                    exit={{ opacity: 0 }} // Fade out effect for the loading component
                    transition={{ duration: 1 }}
                >
                    <motion.div
                        className="absolute top-0 left-0 h-full z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: loadingComplete ? 0 : 1 }}
                        transition={{ duration: 5 }}
                    />
                    <Canvas className="" camera={{ fov: fov }}>
                        {/* Set dark background color */}
                        <color attach="background" args={['#000']} />
                        {/* <Sky /> */}
                        {/* <Effects /> */}
                        <AFormation path={path} onAnimationComplete={() => setAnimationComplete(true)} progress={progress} />
                        <OrbitControls />
                    </Canvas>
                </motion.div>
            ) : (
                <div>
                    <div className='h-[] bg-black'></div>
                    <div className='absolute top-0 left-0 text-white bg-black h-[] w-[100vw]'><Counter progress={progress} /></div>
                </div>
            )}
        </div>
    );
}
