import React from "react"
import { motion } from 'framer-motion';
import DiamondScene from "../Diamond3DModel/DiamondScene";


const Home: React.FC = () => {
    return (
     
            <motion.div
              className="text-gray-900 sample-button cursor-pointer shadow-lg text-center w-[100vw] h-[100vh] mt-[35px]"
              transition={{ opacity: { duration: 0.8 } }}
            >
              <DiamondScene />
            </motion.div>
    )
}

export default Home;