import React from 'react';
import { motion } from 'framer-motion';
import AnimatedButton from '../AnimatedButton/EmailButton';
import HoverTest from '../AnimatedButton/EmailButton';
import EmailButton from '../AnimatedButton/EmailButton';
import MenuButton from '../MenuButton/MenuButton';
import Header from '../Header/Header';

export default function About() {
    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 2 }}>

                <div className="about-container p-8 max-w-4xl mx-auto text-gray-800">
                    <h1 className="text-4xl font-bold text-center mb-8 text-gray-400">About AnyDay Creative Solutions, LLC</h1>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-4">Who We Are</h2>
                        <p className="leading-relaxed">
                            Welcome to <strong>AnyDay Creative Solutions, LLC</strong>, a creative software company that redefines the boundaries of innovation through cutting-edge technology and immersive digital experiences.
                            Founded with a passion for extraordinary solutions, we specialize in the development of software that integrates artistic creativity with advanced online systems, empowering users to interact with digital spaces in ways previously unimaginable.
                        </p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-4">What We Do</h2>
                        <p className="leading-relaxed">
                            At AnyDay Creative Solutions, we bring together the worlds of <strong>3D art</strong>, interactive design, and software engineering to craft digital products that inspire and engage.
                            Our core offerings include:
                        </p>
                        <ul className="list-disc ml-6 mt-4">
                            <li><strong>Interactive 3D Art:</strong> We develop highly immersive 3D models and environments that users can interact with online, providing both aesthetic and functional digital experiences.</li>
                            <li><strong>Custom Software Development:</strong> From complex web applications to mobile experiences, we deliver custom software solutions tailored to meet the unique needs of our clients.</li>
                            <li><strong>Online System Integration:</strong> We specialize in seamlessly integrating our creative solutions into existing online platforms, enhancing the user experience through intuitive and visually stunning interfaces.</li>
                            <li><strong>Creative Digital Solutions:</strong> From content creation to interactive branding, we offer digital solutions that captivate and engage your audience.</li>
                        </ul>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
                        <p className="leading-relaxed">
                            At AnyDay Creative Solutions, we aim to <strong>unlock the full potential</strong> of digital art and technology. We believe that every interaction online should be meaningful, immersive, and above all, memorable.
                            Our mission is to provide users with <strong>extraordinary digital experiences</strong> that blend the beauty of art with the power of technology.
                        </p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-4">Our Vision</h2>
                        <p className="leading-relaxed">
                            Our vision is to become a <strong>global leader in creative software development</strong>, where digital art and interactive technology coexist to inspire, educate, and entertain.
                            We strive to push the boundaries of what technology can achieve and make it accessible for businesses and individuals alike.
                        </p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-4">Why Choose Us?</h2>
                        <ul className="list-disc ml-6 mt-4">
                            <li><strong>Innovative Approach:</strong> We don’t just follow trends; we create them. Our team is at the forefront of digital art and software development, constantly pushing the envelope of what’s possible.</li>
                            <li><strong>Customer-Centric Solutions:</strong> Each project is approached with the client's vision at the center, ensuring that our solutions align with your business goals and exceed expectations.</li>
                            <li><strong>Endless Possibilities:</strong> Whether you're looking to engage your audience with interactive 3D environments or develop a sleek, modern online system, AnyDay Creative Solutions has the expertise to bring your ideas to life.</li>
                        </ul>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                        <p className="leading-relaxed">
                            We’re always excited to collaborate and explore new possibilities. If you're interested in learning more about our services or have a project in mind, don’t hesitate to reach out.
                        </p>
                        <p className="mt-4">
                            <strong>Phone:</strong> +1(513) 855-2575 <br />
                            <strong>Location:</strong> Delaware, USA
                        </p>
                        <p className="mt-4">Let’s create something extraordinary together!</p>
                    </section>
                </div>
            </motion.div>
        </>
    );
}


