import React, { useState } from 'react';
import axios from 'axios'
import { motion } from 'framer-motion';
import AnimatedButton from '../AnimatedButton/EmailButton';
import HoverTest from '../AnimatedButton/EmailButton';
import EmailButton from '../AnimatedButton/EmailButton';
import MenuButton from '../MenuButton/MenuButton';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post('http://localhost:5000/send-email', formData);
            setLoading(false);
            setSuccess(true);
            console.log('Email sent successfully:', response.data);
        } catch (err) {
            setLoading(false);
            setError('Failed to send email. Please try again later.');
            console.error(err);
        }
    }
    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 2 }}>
                <div className="flex flex-col min-h-screen">
                    <div className="flex-grow">
                        <div className="container mx-auto p-8 max-w-4xl">
                            <h1 className="text-4xl font-bold text-center mb-8 text-gray-400">Contact Us</h1>

                            {success ? (
                                <div className="text-center text-green-600 font-semibold">Your message has been sent!</div>
                            ) : (
                                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-8 lg:w-[25vw]">
                                    <div className="mb-6">
                                        <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            placeholder="Your name"
                                            required
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            placeholder="Your email"
                                            required
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">
                                            Message
                                        </label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            placeholder="Your message"
                                            rows="5"
                                            required
                                        />
                                    </div>

                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="px-6 py-2 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition duration-300"
                                            disabled={loading}
                                        >
                                            {loading ? 'Sending...' : 'Submit'}
                                        </button>
                                    </div>

                                    {error && <div className="text-red-600 text-center mt-4">{error}</div>}
                                </form>
                            )}
                        </div>
                    </div>

                    {/* <footer className="bg-gray-800 text-white py-8 mt-auto px-6 rounded-sm">
                        <div className="container mx-auto text-center">
                            <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
                            <p className="mb-2">Address: AnyDay Creative Solutions, LLC,131 Continental Dr, Suite 305, Newark City, Delaware, USA</p>
                            <p className="mb-2">Phone: +1(513) 855-2575</p>
                            <p className="mb-2">Email: <a href="mailto:info@anydayllc.com" className="underline">info@anydayllc.com</a></p>
                            <div className="mt-6">
                                <ul className="flex justify-center space-x-6">
                                    <li><a href="#" className="hover:text-gray-400">Facebook</a></li>
                                    <li><a href="#" className="hover:text-gray-400">Twitter</a></li>
                                    <li><a href="#" className="hover:text-gray-400">LinkedIn</a></li>
                                </ul>
                            </div>
                        </div>
                    </footer> */}
                </div>
            </motion.div>
        </>
    );
}
