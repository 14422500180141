import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import Connector from "./Connector";


export default function RotatingGroup({ connectors, children }) {
    // Reference to the group
    const groupRef = useRef();

    // Use frame to rotate the group gradually
    useFrame((state, delta) => {
        if (groupRef.current) {
            groupRef.current.rotation.y += delta * 0.2;  // Adjust the speed of rotation here
        }
    });

    return (
        <group ref={groupRef}>
            {connectors.map((props, i) => <Connector key={i} {...props} />)}
            {children}
        </group>
    );
}