import React, { useRef, useEffect, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
// import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Text, useMask, Mask } from '@react-three/drei';
import { easing } from 'maath';
// import Progress from '../Counter/Progress';


export default function AFormation({ path, onAnimationComplete }) {
    const leftBoxRef = useRef();
    const rightBoxRef = useRef();
    const leftLoadingBoxRef = useRef();
    const rightLoadingBoxRef = useRef();
    const whiteBoxRef = useRef();
    const { scene } = useThree();
    const backgroundColorRef = useRef(new THREE.Color(0x000000));

    const { camera, gl, size } = useThree();
    const navigate = useNavigate();


    // State variables for controlling animation
    const animationValuesRef = useRef({
        leftBoxRotation: 0,
        leftBoxX: 0,
        leftBoxY: -0.5,
        rightBoxRotation: 0,
        rightBoxX: 0,
        rightBoxY: -0.5,
        pivotPointY: -0.25,
        zoomLevel: 9,
        zoomCompleted: false,
        loadingComplete: false,
        backgroundColor: '#fff'
    });

    const [startLoading, setStartLoading] = useState(true);
    const [startAnimation, setStartAnimation] = useState(false);
    const [startZoom, setStartZoom] = useState(false);
    const [startRotation, setStartRotation] = useState(false);
    const [startBackgroundTransition, setStartBackgroundTransition] = useState(false);
    const [progress, setProgress] = useState(0);
    const [device, setDevice] = useState("desktop");

    const createLeftLoadingBoxGeometry = () => {
        const boxGeometry = new THREE.BoxGeometry(boxWidth * 2, boxHeight, 0.05);
        boxGeometry.translate(1.5, 0, 0); // Shift the geometry's origin to the left edge
        return boxGeometry;
    };

    // Use linear interpolation (lerp) for smooth animation transitions
    const lerp = (start, end, t) => start + (end - start) * t;

    // Helper function to convert degrees to radians
    const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

    const isSmallDevice = size.width < 768;

    // Set up a delay before starting the animation
    useEffect(() => {

        const cameraZoom = 9;
        camera.position.set(0, 0.05, 9); // Ensure camera starts at [0, 0.05, 9]

        const delay = setTimeout(() => {
            setStartAnimation(true); // Start animation after the delay
        }, 2000); // 2000ms delay (2 seconds)

        return () => clearTimeout(delay); // Clean up timeout when component unmounts
    }, [size, camera]);



    const boxWidth = 1.5;
    const boxHeight = 0.3;
    const fontSize = 0.1;

    useFrame(() => {

        // Loading animation: moving the white boxes over the gray ones
        if (startAnimation && !animationValuesRef.current.loadingComplete) {
            setStartLoading(true);
            const loadingSpeed = 0.02;

            // Move the loading boxes over the gray boxes
            if (leftLoadingBoxRef.current && rightLoadingBoxRef.current) {

                leftLoadingBoxRef.current.scale.x = leftLoadingBoxRef.current.scale.x - loadingSpeed;

                leftLoadingBoxRef.current.scale.x = THREE.MathUtils.clamp(
                    leftLoadingBoxRef.current.scale.x,
                    0,
                    5
                );

                // Update the progress value in real-time
                const currentProgress = Math.round((1 - leftLoadingBoxRef.current.scale.x) * 100)
                // console.log(Math.round(currentProgress))
                setProgress(Math.round(currentProgress)); // Update progress based on the current scale

                // Check if loading is complete
                if (progress === 100) {
                    animationValuesRef.current.loadingCompleted = true;
                    setStartLoading(false); // Stop loading animation
                    setTimeout(() => {
                        setStartRotation(true); // Trigger the rotation after the delay
                    }, 1000); // Adjust delay (1000 ms = 1 second) as needed

                }

            }

            // Animate the left box (rotate)
            const updatedLeftBoxRotation = lerp(animationValuesRef.current.leftBoxRotation, Math.PI / 3, 0.04);
            // Animate the right box (rotate)
            const updatedRightBoxRotation = lerp(animationValuesRef.current.rightBoxRotation, -Math.PI / 3, 0.04);

            // Apply transformations to groups
            if (leftBoxRef.current && rightBoxRef.current) {
                leftBoxRef.current.rotation.z = animationValuesRef.current.leftBoxRotation;
                rightBoxRef.current.rotation.z = animationValuesRef.current.rightBoxRotation;
            }

            // console.log(startRotation)
            if (startRotation) {
                // Update rotation values directly
                animationValuesRef.current.leftBoxRotation = updatedLeftBoxRotation;
                animationValuesRef.current.rightBoxRotation = updatedRightBoxRotation;
            }

            // Once the A-formation is complete, start zooming
            if (Math.abs(updatedLeftBoxRotation - Math.PI / 3) < 0.01) {
                setStartZoom(true); // Start the zoom effect once the boxes are in place
            }

        }

        // Zoom in through the "A" formation once it's formed
        if (startZoom && !animationValuesRef.current.zoomCompleted) {

            const zoomStart = 9; // Initial camera position (zoomed out)
            const zoomEnd = 0.01; // Final camera position (zoomed in)
            const zoomFactor = 0.04; // Speed of zoom
            const updatedZoomLevel = lerp(camera.position.z, zoomEnd, zoomFactor);
            camera.position.z = updatedZoomLevel;

            // Use the same zoom factor to control background color transition
            const progress = (zoomStart - updatedZoomLevel) / (zoomStart - zoomEnd); // Normalized progress from 0 to 1
            const targetColor = new THREE.Color(0xFFFFFF); // White color
            backgroundColorRef.current.lerp(targetColor, progress); // Use progress to synchronize color transition

            // Apply the updated background color to the scene in every frame
            // gl.setClearColor(backgroundColorRef.current);

            const epsilon = 0.001
            if (Math.abs(updatedZoomLevel - zoomEnd) < epsilon) {
                animationValuesRef.current.zoomCompleted = true;
                setStartBackgroundTransition(true);
            }
        }

        // Apply the updated background color to the scene
        if (animationValuesRef.current.zoomCompleted && startBackgroundTransition) {
            const transitionSpeed = 0.01;
            const targetColor = new THREE.Color(0xFFFFFF)
            backgroundColorRef.current.lerp(targetColor, transitionSpeed);
            // scene.background = backgroundColorRef.current;
            gl.setClearColor(backgroundColorRef.current)

            // If background is sufficiently white, trigger the navigation
            if (backgroundColorRef.current.r >= 0.9) {
                navigate(path); // Navigate to the new page
                onAnimationComplete();
            }
        }

    });

    return (
        <>
            {/* Add AxesHelper to visualize the axes */}
            {/* <axesHelper args={[2]} /> */}

            {/* Add small spheres to visualize axis points */}
            {/* <mesh position={[0, 0, 0]}>
                <sphereGeometry args={[0.05, 32, 32]} />
                <meshBasicMaterial color="white" />
            </mesh> */}
            {/* <mesh position={[0, -0.25, 0]}>
                <sphereGeometry args={[0.05, 32, 32]} />
                <meshBasicMaterial color="green" />
            </mesh> */}
            {/* 
            <mesh position={[animationValuesRef.current.leftBoxX, animationValuesRef.current.leftBoxY, 0]}>
                <sphereGeometry args={[0.05, 32, 32]} />
                <meshBasicMaterial color="red" />
            </mesh> */}

            {/* Left Box Group with pivot at (0, -0.25) */}
            {(
                <group
                    ref={leftBoxRef}
                    position={[animationValuesRef.current.leftBoxX, animationValuesRef.current.leftBoxY, 0]}

                >
                    <group
                    // I spent a significan amount of time to resolve this issue with rotation
                    // rotation={[0, 0, animationValuesRef.current.leftBoxRotation]}
                    >
                        <mesh
                            position={[-1.2, 0.25, 0]}
                            ref={whiteBoxRef}
                            scale={1.6}>
                            <boxGeometry args={[boxWidth, boxHeight, 0.05]} />
                            <meshBasicMaterial color="white" />
                        </mesh>
                    </group>
                </group>
            )}


            {/* Left Box Group with pivot at (0, -0.25) */}
            {/* Test */}
            {
            }




            {/* Right Box Group with pivot at (0, -0.25) */}
            {
                (<group
                    ref={rightBoxRef}
                    // position={[0, animationValues.pivotPointY, 0]}
                    position={[animationValuesRef.current.rightBoxX, animationValuesRef.current.rightBoxY, 0]}
                >
                    <group
                    // I spent a significan amount of time to resolve this issue with rotation
                    // rotation={[0, 0, animationValuesRef.current.rightBoxRotation]}
                    >
                        <mesh
                            position={[1.2, 0.25, 0]}
                            // rotation={[0, 0, animationValues.leftBoxRotation]}
                            scale={1.6}>
                            <boxGeometry args={[boxWidth, boxHeight, 0.05]} />
                            <meshBasicMaterial color="white" />
                        </mesh >
                    </group >
                </group >)
            }

            {/* White Loading Boxes */}
            {(<group
                ref={leftLoadingBoxRef}
                // position={[0, animationValues.pivotPointY, 0]}
                position={[animationValuesRef.current.rightBoxX, animationValuesRef.current.rightBoxY, 0]}
            >
                <group
                // I spent a significan amount of time to resolve this issue with rotation
                // rotation={[0, 0, animationValuesRef.current.rightBoxRotation]}
                >
                    <mesh
                        position={[-2.4, 0.25, 0]}
                        // rotation={[0, 0, animationValues.leftBoxRotation]}
                        geometry={createLeftLoadingBoxGeometry()}
                        scale={1.6}>
                        {/* <boxGeometry args={[0.01, boxHeight, 0.05]} /> */}

                        <meshBasicMaterial color="#ec18fd" />
                    </mesh>
                </group>
            </group>)}
            <group
                ref={rightLoadingBoxRef}
                // position={[0, animationValues.pivotPointY, 0]}
                position={[animationValuesRef.current.rightBoxX, animationValuesRef.current.rightBoxY, 0]}
            >
            </group>
            <group>
                <Text
                    position={[-0.1, -0.44, 0]}
                    rotation={[degreesToRadians(270), degreesToRadians(29), 0]}
                    fontSize={0.1}
                    font="Inter-Regular.woff"
                    letterSpacing={-0.05}
                    color="#ec18fd"
                    anchorX="center"
                    anchorY="middle"
                >Any</Text>
                <Text
                    position={[0.1, -0.44, 0]}
                    rotation={[degreesToRadians(270), degreesToRadians(331), 0]}
                    fontSize={0.1}
                    font="Inter-Regular.woff"
                    letterSpacing={-0.05}
                    color="#14d4df"
                    anchorX="center"
                    anchorY="middle"
                >Day</Text>
                <Text
                    position={[0.1, -0.2, 0]}
                    rotation={[degreesToRadians(270), degreesToRadians(331), 0]}
                    fontSize={0.01}
                    font="Inter-Regular.woff"
                    letterSpacing={-0.05}
                    color="#14d4df"
                    anchorX="center"
                    anchorY="middle"
                >©️</Text>
            </group>
            <Text
                position={[-0.15, -0.44, 0.05]}
                rotation={[degreesToRadians(270), degreesToRadians(29), 0]}
                fontSize={0.02}
                font="Inter-Regular.woff"
                letterSpacing={-0.03}
                color={"white"}
                anchorX="center"
                anchorY="middle"
            >Creative Solutions</Text>
            {/* <Text
                position={isMobile ? [-0.2, -1.2, 1.5] : [-0.15, -0.44, 0.05]}
                rotation={isMobile ? [-Math.PI / 2, degreesToRadians(0), 0] : [-Math.PI / 2, degreesToRadians(29), 0]}
                fontSize={isMobile ? 0.01 : 0.02}
                color={isMobile ? "red" : "white"}
                anchorX="center"
                anchorY="middle"
            >Creative Solutions</Text> */}

            {/* Counter for progress */}
            {/* <group position={[-6, -5.5, 0]}>
                {[...`${progress}`.padStart(3, "0")].map((num, index) => (
                    <Counter key={index} index={index} value={parseInt(num, 10)} color="#ec18fd" />
                ))}
                <Text children="%" position={[-6, -5.5, 0]} fontSize={0.6} font="Inter-Regular.woff" color="#ec18fd" />
            </group> */}
        </>
    );
}
