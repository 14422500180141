import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import LoadingComponent from './components/Loading/LoadingComponent';
import NextPage from './components/NextPage/NextPage';
import Header from './components/Header/Header';
import About from './components/About/About';
import { Portfolio } from './components/Portfolio/Portfolio';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home';

// New component to handle location and path changes
function PathUpdater({ setCurrentPath }: { setCurrentPath: React.Dispatch<React.SetStateAction<string>> }) {
    const location = useLocation(); // This works because it's inside Router
    useEffect(() => {
        setCurrentPath(location.pathname); // Update the current path whenever the location changes
        console.log("Path Updater", location.pathname)
    }, [location.pathname, setCurrentPath]);

    return null; // This component doesn't render anything, just handles the path updates
}

// Define the AnimatedRoutes component
export function AnimatedRoutes() {
    const location = useLocation(); // Get the current location

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                {/* Define routes for Header and NextPage */}
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </AnimatePresence>
    );
}

export default function App() {
    const [loading, setLoading] = useState(true); // Initial loading state is true
    const [currentPath, setCurrentPath] = useState('/'); // Track the current path to pass to LoadingComponent

    const handleLoadingComplete = () => {
        setLoading(false); // Stop loading when the onComplete callback is triggered
    };

    const loading2 = 'test';

    return (
        <Router> {/* Ensure Router wraps the entire app */}
            <div className="App">
                {/* PathUpdater handles updating the current path dynamically */}
                <PathUpdater setCurrentPath={setCurrentPath} />

                {loading ? (
                    // Use the updated currentPath to determine where to navigate after loading
                    <LoadingComponent onComplete={handleLoadingComplete} path={currentPath} />
                ) : (
                    <div>
                    <Header />
                    </div>
                   
                )}
            </div>
        </Router>
    );
}
