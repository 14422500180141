import * as React from "react";
import { useEffect, useState } from "react";
import { motion, useAnimation, transform } from "framer-motion";
import "./Counter.css"

const mapRemainingToSpringVelocity = transform([0, 5], [50, 0]);

interface CounterProps {
  progress: number; // Progress passed in as a prop
}

export const Counter: React.FC<CounterProps> = ({ progress }) => {
  const target = 100; // Target number for completion
  const [counter, setCounter] = useState(1); // Start from 1
  const controls = useAnimation();

  useEffect(() => {
    // Update counter based on the progress prop
    if (progress <= target) {
      setCounter(progress); // Update the counter with the new progress
    }

    controls.start({
      scale: 1,
      transition: {
        type: "spring",
        velocity: mapRemainingToSpringVelocity((target - progress) / target),
        stiffness: 2000,
        damping: 1000,
      },
    });
  }, [progress, counter, target, controls]);

  return (
    <h1 className="">
        <div className="flex justify-center items-center h-[100vh]">
            <div className="h-[50vh]">
                <motion.span animate={controls}>{counter}</motion.span></div>
            <div className="h-[50vh]"><span>%</span></div>
        </div>
    </h1>
  );
};
